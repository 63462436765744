@import "../vars";

.ant-modal {
  max-width: calc(100vw - 16px) !important;
  transform-origin: 50% 50% !important;
}

.ant-modal-body {
  padding: 40px;
}

.ant-modal-content {
  h3 {
    font: $font-h3;
  }

  .ant-modal-close {
    top: 5px;
    right: 5px;
  }
}

.ant-modal-confirm-body {
  i.anticon{
    display: none;
  }

  .ant-modal-confirm-title {
    font-size: 18px;
    color: $dark;
  }

  .ant-modal-confirm-content {
    margin-left: 0 !important;
  }
}

.ant-modal-header {
  .ant-modal-title {
    color: $gray-light;
    text-align: center;
  }

  .ant-modal-close-icon {
    color: $gray-light;
  }
}

.ant-modal-footer {
  display: flex;
  justify-content: center;
  padding: 15px 16px;

  & button + button {
    margin-left: 20px;
  }
}

.ant-modal-close {
  width: 24px !important;
  height: 24px !important;
  .ant-modal-close-x {
    width: auto;
    height: auto;
    line-height: 20px;
    font-size: 20px;
    padding: 5px;
  }
}
.ant-modal-close-x {
  width: auto;
  height: auto;
  line-height: 20px;
  font-size: 20px;
  padding: 5px;
}

.ant-modal-mask {
  background-color: rgba(0,0,0,0.3) !important;
}

.ant-modal-close {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 30px;
  right: 30px;
  background-color: transparent;
  color: $color-text-secondary;

  &:hover {
    color: $color-text;
  }
}
