@import './vars.scss';

%not-selectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

%standard-page-wrapper {
  width: 100%;
  padding: 20px;
  overflow-y: auto;
  min-height: calc(100vh - 80px - 80px);

  @media screen and (max-width: $screen-lg) {
    min-height: calc(100vh - 64px - 100px);
  }

  @media screen and (max-width: $screen-md) {
    min-height: calc(100vh - 64px - 120px);
  }

  @media screen and (max-width: $screen-xs) {
    padding: 10px;
  }

  @media screen and (max-width: $screen-xxs) {
    padding: 5px;
  }
}

%standard-pagination-wrapper {
  width: 100%;
  padding: 20px;

  @media screen and (max-width: $screen-xs) {
    padding: 10px;
  }

  @media screen and (max-width: $screen-xxs) {
    padding: 5px;
  }
}
