@import '../vars.scss';

.ant-btn.ant-btn-link {
  padding: 0;
  height: initial;
  color: $color-primary;
  border: none;

  &:hover,
  &:focus {
    border: none;
    background-color: transparent;
    color: $color-primary;
  }
}

.ant-btn.ant-btn-circle {
  padding: 0;
}

.ant-modal-confirm-btns {
  width: 100%;
  text-align: center;
  border-top: $border-color;
  padding-top: 15px;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: $color-primary;
  border-color: $color-primary;
}

.ant-btn:focus, .ant-btn:hover {
  border-color: $border-color;
  background-color: $gray-light;
  color: $dark;
}

.ant-btn {
  height: 44px;
  line-height: 42px;
}

.ant-btn-lg {
  padding: 0 30px;
}
