@import "src/assets/styles/vars";
@import "src/assets/styles/placeholder.scss";

.pageWrapper {
  @extend %standard-page-wrapper;

  .cardWrapper {
    //width: 1500px;
    width: 1600px;
    max-width: 100%;
    overflow-x: auto;

    padding: 0 20px 20px;

    @media screen and (max-width: $screen-xxs) {
      padding: 0 5px 5px;
    }

    .pageTitle {
      font: $font-h2;
      margin-bottom: 30px;
    }

    .description {
      font: $font-standard;

      .subtitle {
        font: $font-h5;
        margin-top: 20px;
      }

      span {
        font-family: "SFMono-Regular", Consolas, "Liberation Mono", Menlo, Courier, monospace;
        background-color: $light;
        /*margin: 0 10px;*/
      }

      li {
        margin-bottom: 10px;
      }

      button {
        span {
          background-color: transparent;
        }
      }

    }
  }

  .paginationWrapper {
    width: 100%;
    overflow-y: auto;
  }
}

.menuIcon {
  opacity: 0.8;
  margin-right: 20px;
}

.paginationWrapper {
  @extend %standard-page-wrapper;
}

.spinner {
  height: calc(100vh - 64px) !important;
}

.buttonsIcon {
  margin-right: 15px;
}
