@import '../vars.scss';

/*.ant-form-item-control {
  height: 65px !important;
}

.ant-form-item-control-input, .ant-input-affix-wrapper {
  height: 42px !important;
  margin: 5px 0 !important;
}*/

input.ant-input, textarea.ant-input, .ant-input-affix-wrapper {
  border-radius: $input-border-radius !important;
  height: 44px !important;
}

.ant-input-affix-wrapper {
  .ant-input {
    top: -5px;
    background-color: transparent;
  }
}

.ant-select-selector {
  height: 44px !important;
  /*border: $border-standard !important;*/
  border-radius: $input-border-radius !important;

  input {
    height: 44px !important;
  }

  .ant-select-selection-placeholder {
    line-height: 44px !important;
  }

  .ant-select-selection-item {
    line-height: 42px !important;
  }
}