$gray: #9c9ba2 !default;
$gray-light: #CDCDD0 !default;
$gray-dark: #383745 !default;
$white: #ffffff !default;
$red: #FB6C55 !default;
$green: #75B241 !default;
$orange: #FEBD2E !default;
$dark: #070516 !default;
$light: #E5E5E5 !default;


$header-color: #FFF;
$border-color: $gray-light;
$color-primary: $orange;
$color-primary-hovered: #FFCD5E;
$color-primary-disables: #FEBD2E;
$color-success: $green;
$color-error: $red;
$facebook-color: #3b5999;
$google-color: #dd4b39;
$color-text: #070516;
$color-text-secondary: $gray;
$color-text-disabled: $gray-light;
$color-notification: #233E2D;
$hovered-menu-item-color: #F5F3F0;
$landing-gradient-dark-side: #F5F3F0;


$screen-xxs: 420px;
$screen-xs: 480px;
$screen-sm: 576px;
$screen-md: 768px;
$screen-lg: 992px;
$screen-xl: 1200px;
$screen-xxl: 1600px;

$text-size: 14px;
$small-text-size: 12px;
$h4-text-size: 16px;
$h3-text-size: 18px;
$h2-text-size: 21px;

$font-small: normal normal normal 12px/16px "Rubik", sans-serif;
$font-standard: normal normal normal 14px/20px "Rubik", sans-serif;
$font-h5: normal normal 500 14px/20px "Rubik", sans-serif;
$font-h4: normal normal 500 16px/24px "Rubik", sans-serif;
$font-h3: normal normal 500 18px/28px "Rubik", sans-serif;
$font-h2: normal normal bold 21px/28px "Rubik", sans-serif;


$button-border-radius: 5px;
$card-border-radius: 5px;
$input-border-radius: 5px;
$modal-border-radius: 10px;

$border-standard: 1px solid $border-color;

$animation-duration-slow: 0.45s;
$animation-duration-base: 0.2s;