@import '../vars.scss';

.ant-table-tbody > tr > td.ant-table-selection-column {
  padding-left: 10px;
  padding-right: 0;
  text-align: left;
}

.ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
  background: $gray-light !important;
  cursor: pointer;
}

.ant-table-thead > tr > th {
  background-color: transparent;
  color: $color-text-secondary;
}
