.ant-form-item {
  margin-bottom: 0 !important;
  width: 100%;

  .ant-form-item-control-input {
    height: 44px !important;
  }

  .ant-form-item-control {
    height: 68px !important;
  }

  .ant-input-affix-wrapper {
    input.ant-input {
      height: 31px !important;
    }
  }
}

/*.ant-form-item-label {
  line-height: 12px !important;
  padding-bottom: 3px;
}*/

.ant-input-search {
  height: 44px;
}