@import "../vars.scss";

$rounded-border-radius: 12px;
$not-rounded-border-radius: 3px;

.ant-checkbox-wrapper {
  border-radius: $rounded-border-radius;
  .ant-checkbox {
    height: 24px;
    width: 24px;
    border-radius: $rounded-border-radius;
    .ant-checkbox-input {
      height: 24px;
      width: 24px;
      border-radius: $rounded-border-radius;
    }

    .ant-checkbox-inner {
      height: 24px;
      width: 24px;
      border-radius: $rounded-border-radius;
      border-color: transparent;
      background-color: $gray-light;
      &::after {
        height: 14px;
        width: 6px;
        color: $color-text;
        border-color: $color-text;
        left: 27%;
        top: 48%;

      }
    }

    &::after {
      height: 24px;
      width: 24px;
      border-radius: $rounded-border-radius;
      border-color: transparent;
    }

    &.ant-checkbox-checked {
      .ant-checkbox-inner {
        background-color: $color-primary;

        &:hover {
          border-color: transparent !important;
          outline: none !important;
        }

      }

    }
  }

  &.ant-checkbox-wrapper-checked {

  }

  &.not-rounded {
    border-radius: $not-rounded-border-radius;

    .ant-checkbox {
      border-radius: $not-rounded-border-radius;

      .ant-checkbox-input {
        border-radius: $not-rounded-border-radius;
      }

      .ant-checkbox-inner {
        border-radius: $not-rounded-border-radius;
      }

      &::after {
        border-radius: $not-rounded-border-radius;
      }
    }
  }
}