@import '../../../../assets/styles/vars.scss';

.ItemCreatorWrapper {
  .ant-select-selector {
    //border: none !important;
    //height: calc(100% + 1px) !important;
    //border-radius: 0 !important;
    margin: 0;
    background-color: transparent;

    input {
      //border: none !important;
      //height: calc(100% - 2px);
      padding-right: 120px;
      margin: 0;
      background-color: transparent;
    }
  }

  /*.ant-input-number {
    height: 100% !important;

    .ant-input-number-input-wrap {
      height: 100%;
      input {
        border: none !important;
        height: 100%;
        margin: 0;
        background-color: transparent;
        color: $color-text;
        text-align: end;
        padding-right: 26px;
      }
    }

  }*/
}