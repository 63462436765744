@import '../vars.scss';

.ant-notification {
  .ant-notification-notice {
    background: $color-notification !important;
  }

  .ant-notification-notice-message,
  .ant-notification-notice-close,
  .ant-notification-notice-close .anticon {
    color: $white !important;
  }
}
