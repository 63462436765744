@import "../vars";

.ant-select-dropdown {
  width: max-content !important;
  max-width: 60vw;
  overflow-x: hidden;

  .ant-select-dropdown-menu {
    width: max-content !important;
    max-width: 60vw;
    overflow-x: hidden;
  }
}

.ant-select-search__field__wrap, .ant-select-search, .ant-select-search--inline, .ant-select-selection__rendered {
  width: max-content !important;
  max-width: 60vw;
}

.ant-dropdown-menu {
  width: max-content !important;
  max-width: 60vw;
  overflow-x: hidden;
  border-radius: $modal-border-radius !important;
  padding: 10px 0;

  .ant-dropdown-menu-item {
    max-width: 60vw;
    overflow-x: hidden;
    padding: 10px 15px;

    div {
      margin: -10px -15px;
      padding: 10px 15px;
    }
  }
}
