@import '../vars.scss';

.ant-table-pagination {
  float: none !important;
  text-align: center !important;
  margin: 24px 0 16px !important;
  @media screen and (max-width: $screen-xs) {
    text-align: start !important;
  }
}

.ant-pagination-item,
.ant-pagination-prev a,
.ant-pagination-next a {
  border: none !important;
}

.ant-pagination-item-active a {
  color: $color-primary !important;
  font-weight: 700 !important;
}

.ant-pagination-item {
  height: initial;
  min-width: 30px;
  @media screen and (max-width: $screen-sm) {
    min-width: 22px;
    font-size: 13px;
  }
  @media screen and (max-width: $screen-xs) {
    min-width: 20px;
    font-size: 12px;
  }
}

.ant-pagination-item-link {
  .anticon {
    position: relative;
    top: -3px;
  }
}

.ant-pagination-total-text {
  color: $color-text-secondary;
  @media screen and (max-width: $screen-xs) {
    font-size: 13px;
  }
  @media screen and (max-width: $screen-xs) {
    font-size: 12px;
  }
}
