@import "vars";
@import "inputs";
@import "antd/index";
@import "fonts";

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  background-color: $white;
}